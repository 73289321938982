import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["select", "hidden", "form"];

    connect() {
        // Listen for changes to update the hidden field
        this.selectTarget.addEventListener('change', this.updateHiddenField.bind(this));

        // Listen for clicks on the document
        document.addEventListener('click', this.handleDocumentClick.bind(this));

        // Flag to track if the select has been opened
        this.selectOpened = false;
    }

    disconnect() {
        document.removeEventListener('click', this.handleDocumentClick.bind(this));
    }

    updateHiddenField() {
        console.log('Updating hidden field');
        const selectedValues = Array.from(this.selectTarget.selectedOptions, option => option.value);
        this.hiddenTarget.value = selectedValues.join(',');
        this.selectOpened = true;
    }

    handleDocumentClick(event) {
        // If the select has been opened and the click is outside
        if (this.selectOpened && !this.selectTarget.contains(event.target)) {
            this.submitForm();
            // Reset the flag after submission
            this.selectOpened = false;
        }
    }

    submitForm() {
        console.log('Submitting form');
        this.formTarget.submit();
    }
}